import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from "../components/richtextOptions"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Container from "@material-ui/core/Container"
import SimpleCard from "../components/simpleCard"
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "100px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  titolo: {
    color: "#fe5f55",
    fontSize: "2rem",
    fontWeight: 900,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  sottotitolo: {
    color: "#c3c3c3",
    fontSize: "1.5rem",
    fontWeight: 400,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  highlight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  data: {
    color: "rgba(117, 117, 117, 1)",
    fontSize: "1rem",
    fontWeight: 200,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    boxSizing: "inherit",
  },
  articolo: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
}))

const Article = ({ data: { post }, pageContext, location }) => {
  const classes = useStyles()
  const url = location.href ? location.href : ""
  return (
    <Layout
      title={post.titolo}
      description={post.sottotitolo}
      image={post.foto.fluid.src}
      isArticle={true}
      url={"/articoli/" + post.slug}
      date={post.data}
    >
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent} maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <h1 className={classes.titolo}>{post.titolo}</h1>
              </Grid>
              <Grid item xs={12}>
                <h3 className={classes.sottotitolo}>{post.sottotitolo}</h3>
              </Grid>
              <div className={classes.highlight}>
                <div>
                  <h5 className={classes.data}>{post.data}</h5>
                </div>
                <div>
                  <FacebookShareButton
                    url={url}
                    hashtag="#jsdbasket"
                    quote="Guardate cosa ho trovato tra gli articoli del Jesolo San Donà Basket"
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  <WhatsappShareButton url={url} title={post.title}>
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={url}
                    subject="Guarda cosa ho trovato tra gli articoli del Jesolo San Donà Basket"
                  >
                    <EmailIcon size={40} round={true} />
                  </EmailShareButton>
                </div>
              </div>
              <Grid item xs={12}>
                <img
                  alt={post.foto.title}
                  src={post.foto.fluid.src}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.articolo}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                {documentToReactComponents(post.testo.json, options)}
              </Grid>
            </Grid>
            <Divider />
            <br />
            <Grid item container xs={12} spacing={5}>
              {pageContext.prev !== null ? (
                <Grid item xs={12} sm={6} md={4}>
                  <SimpleCard
                    title={pageContext.prev.titolo}
                    slug={"/articoli/" + pageContext.prev.slug}
                    descrizione={pageContext.prev.descrizione}
                    data={new Date(pageContext.prev.data).toLocaleDateString()}
                    image={pageContext.prev.foto}
                    type="articoli"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={4}></Grid>
              )}
              {pageContext.next !== null ? (
                <Grid item xs={12} sm={6} md={4}>
                  <SimpleCard
                    title={pageContext.next.titolo}
                    slug={"/articoli/" + pageContext.next.slug}
                    descrizione={pageContext.next.descrizione}
                    data={new Date(pageContext.next.data).toLocaleDateString()}
                    image={pageContext.next.foto}
                    type="articoli"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={4}></Grid>
              )}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    post: contentfulArticolo(
      node_locale: { eq: "it-IT" }
      slug: { eq: $slug }
    ) {
      titolo
      sottotitolo
      slug
      descrizione
      data(formatString: "DD/MM/YYYY")
      foto {
        title
        fluid(quality: 100) {
          src
        }
      }
      testo {
        json
      }
    }
  }
`
